.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
}

.row {
    display: flex;
    flex-direction: row;    
    padding: 0.5em;
}

.row div {
    padding: 0.5em;
}

.card-container {
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

html, body, #root {
    height: 100%;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 90%;
}

.content {
    flex: 1;
    padding-bottom: 100px; /* Equal to footer height */
    box-sizing: border-box;
}

.footer {
    height: 100px; /* Set the height of the footer */
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
}